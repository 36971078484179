import * as React from 'react';
import ingestIcon from './assets/ingest.png';
import visualizeIcon from './assets/visualize.png';
import analyzeScaleIcon from './assets/analyze-scale.png';
import CloudAnalysis from '../../common/CloudAnalysis';

const items = [
  {
    title: 'Ingest & manage',
    description:
      'Easily ingest data in-parallel, while optimizing processing for high speed and low cost. Manage data and securely share it within, and outside of, your organization.',
    icon: ingestIcon,
  },
  {
    title: 'Analyze at scale',
    description:
      'Run pipelines at scale, without complex infrastructure hassles. Use PDAL and parallelize operations like LiDAR point classification (e.g., SMRF) and more.',
    icon: analyzeScaleIcon,
  },
  {
    title: 'Visualize with ease',
    description:
      'Explore LiDAR data interactively. Power web dashboards that can easily extend 3D visualizations with tools like Babylon.js. Run deeper analysis with a 3D tile server.',
    icon: visualizeIcon,
  },
];

const LidarCloudAnalysis: React.FC = () => (
  <CloudAnalysis
    title="Point cloud analysis made simpler"
    videoUrl={`${process.env.GATSBY_ASSETS_URL}/data-types/lidar/lidar-landing-page.mp4`}
    items={items}
  />
);

export default LidarCloudAnalysis;
