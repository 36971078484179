import React from 'react';
import Capabilities from '../../common/Capabilities';
import cloudPointIcon from './assets/point.png';
import dataSetsParallelIcon from './assets/datasets-parallel.png';
import exploreIcon from './assets/explore.png';
import sliceIcon from './assets/slice.png';
import timeSeriesIcon from './assets/time-series.png';
import interoperabilityIcon from './assets/interoperability.png';
import dataCodeIcon from './assets/data-code.png';
import mlmodelsIcon from './assets/mlmodels.png';
import interactiveVisualizationsIcon from './assets/interactive-visualizations.png';

const capabilities = [
  {
    title: 'Optimize point clouds for analysis',
    description:
      'No more files. Use TileDB to combine thousands of LAS files into one 3D array, even if it’s larger than memory. Manage data in a cloud-native format, and easily time-travel on every point stored.',
    icon: cloudPointIcon,
  },
  {
    title: 'Ingest massive datasets in parallel',
    description:
      'Avoid expensive ingestion tasks for joining datasets. Quickly ingest in bulk with parallel, lock-free TileDB write support. Then use serverless functions to parallelize compute over a specific area of interest (AOI).',
    icon: dataSetsParallelIcon,
  },
  {
    title: 'Explore and share',
    description:
      'Automatically catalog assets and easily share them. Make data and code instantly discoverable through TileDB Cloud search, and quickly collaborate with secure permissions management and sharing options on all of your assets.',
    icon: exploreIcon,
  },
  {
    title: 'Slice rapidly',
    description:
      'Slice arrays using native coordinates across multiple dimensions. TileDB operates directly on cloud object storage, scaling gracefully to many TBs of data with a small memory footprint.',
    icon: sliceIcon,
  },
  {
    title: 'Enjoy time-series support',
    description:
      'Take advantage of built-in support for point cloud time-series analysis. Index data using precise real numbers and datetime objects.',
    icon: timeSeriesIcon,
  },
  {
    title: 'Enable interoperability end-to-end',
    description:
      'Scale way beyond the capabilities of PostGIS. TileDB integrates with existing tools at every stage of your research — from PDAL and QGIS to R & Python Jupyter notebooks and leading 3D visualization tools.',
    icon: interoperabilityIcon,
  },
  {
    title: 'Govern data and code',
    description:
      'Easily define access policies on your data and code, so you can scale collaboration within your organization or across the world. Data governance controls also provide the option to monetize derived products at no extra cost.',
    icon: dataCodeIcon,
  },
  {
    title: 'Train ML models and scale serverlessly',
    description:
      'Centrally manage ML models with plugins for PyTorch, TensorFlow and other ML frameworks. Quickly scale operations, like object detection, using serverless task graphs, no clusters required.',
    icon: mlmodelsIcon,
  },
  {
    title: 'Share interactive LiDAR visualizations',
    description:
      'Take 3D visualizations from your notebooks and expose them directly to the web as interactive dashboards using Babylon.js and other libraries. TileDB Cloud comes with a built-in 3D point cloud tile server that conforms with OGC interoperability specifications.',
    icon: interactiveVisualizationsIcon,
  },
];

const LidarCapabilities = () => {
  return <Capabilities capabilities={capabilities} title="We reimagined your point cloud workflows with TileDB Cloud" />;
};

export default LidarCapabilities;
