import React from 'react';
import links from '@constants/links';
import Resources from '../../common/Resources';
import bookIcon from './assets/Book.svg';
import videoIcon from './assets/video.svg';
import tutorialIcon from './assets/Grad-hat.svg';

const items = [
  {
    title: 'Documentation',
    text: 'Have a technical question? Check out our comprehensive documentation and dig into API reference materials for Python, R, and many other languages.',
    linkText: 'Read the docs',
    link: links.docPages.geospatial,
    icon: bookIcon,
  },
  {
    title: 'Workshop',
    text: 'Watch the replay! Norman Barker, VP of Geospatial, showed several ways to work with large arrays: from parallel ingest to slicing and colorizing 15 million points at scale.',
    linkText: 'Watch the video',
    link: 'https://www.youtube.com/watch?v=lv-GExUVsHc&ab_channel=TileDB%2CInc.',
    icon: videoIcon,
  },
  {
    title: 'Tutorial',
    text: 'Ready to get started? Jump right in with the TileDB LiDAR Quickstart tutorial on TileDB Cloud using Jupyter notebooks directly through its console.',
    linkText: 'Go to tutorial',
    link: links.docPages.lidar,
    icon: tutorialIcon,
  },
];

const LidarResources: React.FC = () => {
  return <Resources items={items} />;
};

export default LidarResources;
