import React from 'react';
import Container from '@components/Container';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import SectionStayConnectedForBlog from '@page-components/Blog/SectionStayConnectedForBlog';
import Related from '@components/IndustryDataType/Related';
import { industries } from '@constants/industriesAndDatatypes';
import Jumbotron from '../common/Jumbotron';
import LidarResources from './LidarResources';
import LidarServiceCombination from './LidarServiceCombination';
import './LidarPage.scss';
import shareImage from './assets/LiDAR_page_thumbanail.png';
import background from './assets/LiDAR_hero.jpg';
import LidarCapabilities from './LidarCapabilities';
import LidarCloudAnalysis from './LidarCloudAnalysis';

const LidarPage: React.FC = () => {
  return (
    <IndexLayout
      pageName="lidar"
      helmet={{
        title: 'TileDB - The complete LiDAR data management and analysis platform',
        description: 'Reduce time from capture to insights with TileDB Cloud.',
        shareImage: {
          url: shareImage,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent" />
      <main className="main LidarPage">
        <Jumbotron
          title="The complete LiDAR data management and analysis platform"
          subtitle="Reduce time from capture to insights with TileDB Cloud."
          backgroundImage={background}
          withGradient
        />
        <LidarCloudAnalysis />
        <LidarCapabilities />
        <LidarServiceCombination />
        <LidarResources />
        <Related items={[industries.telecom, industries.maritime, industries.defense, industries.oilAndGas, industries.reinsurance]} />
        <Container>
          <SectionStayConnectedForBlog />
        </Container>
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default LidarPage;
